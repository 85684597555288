import React, { useEffect, useState } from 'react'
import { graphql, type PageProps } from 'gatsby'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'

import headerLanguageTags from 'utils/src/headerLanguageTags'
import NewsletterModal from 'ui/src/NewsletterModal/NewsletterModal'
import { renderContentBlocks } from '../utils/content-blocks'
import AnalyticsDataLayer from '../utils/analyticsDataLayer'
import Layout from '../components/layout'
import useWebsiteSettings from '../hooks/useWebsiteSettings'
import * as Contentful from 'cw-frontend/src/types/contentful'

interface PageContext {
  locale?: string
}

interface PageData {
  contentfulPage: Contentful.Page
}

export default function Page({ data, pageContext, location }: PageProps<PageData, PageContext>) {
  const { locale } = pageContext
  const page = data.contentfulPage
  const contentBlocks = page.contentBlocks || []

  const { noindex: websiteNoindex } = useWebsiteSettings()

  const noindex = websiteNoindex === 'noindex' || page.noindex === 'noindex'
  const isStoreLocator =
    page.contentBlocks?.some(e => e.__typename === 'ContentfulBlockStoreLocatorMap') === true

  const [hasWindow, setHasWindow] = useState(false)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true)
    }
  }, [])

  return (
    <PageWrapper customBackground={page.customBackground || null}>
      {/* @ts-expect-error Issue with children prop */}
      <Helmet>
        <title>{page.title}</title>
        <meta name="description" content={page.description} />
        <html lang={locale} />
        <meta name="robots" content={noindex ? 'noindex' : 'all'} />
        {page.slug === '/' &&
          headerLanguageTags?.map(({ url, locale: langLocale }) => (
            <link rel="alternate" href={url} hrefLang={langLocale} key={langLocale} />
          ))}
      </Helmet>

      {hasWindow && locale && window.OneTrust
        ? window.OneTrust.changeLanguage(locale.slice(0, 2))
        : null}

      {process.env.GATSBY_BUDDY_PIPELINE !== 'uk' && (
        <AnalyticsDataLayer title={page.title} location={location} />
      )}
      <Layout
        hero={page.hero}
        secondaryNavigation={page.secondaryNavigation}
        location={location}
        isStoreLocator={isStoreLocator}
        noBackground={isStoreLocator}
        alternativeLogo={page.alternativeLogo}
      >
        {contentBlocks.map(component =>
          renderContentBlocks({
            component,
          })
        )}

        {page.newsletterPopup === 'Yes' && <NewsletterModal locale={locale} />}
      </Layout>
    </PageWrapper>
  )
}

export const query = graphql`
  query PageQueryCore($id: String!) {
    contentfulPage(id: { eq: $id }) {
      ...ContentfulPageCoreFragment
    }
  }
`

interface PageWrapperProps {
  customBackground: Contentful.Page['customBackground'] | null
}

const PageWrapper = styled.div<PageWrapperProps>`
  background-image: ${p => (p.customBackground ? `url(${p.customBackground.file.url})` : 'none')};
  background-color: ${p => (p.customBackground ? '#ffffff' : 'initial')};
  background-size: 320px 320px;
  background-repeat: repeat;
  position: relative;
  z-index: 0;
`
